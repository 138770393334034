//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-568:_5648,_5292,_4448,_7332,_6268,_6796,_1312,_4848;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-568')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-568', "_5648,_5292,_4448,_7332,_6268,_6796,_1312,_4848");
        }
      }catch (ex) {
        console.error(ex);
      }